import { type LoaderFunctionArgs } from 'react-router-dom';
import type { MarketoParameters } from 'types';

const marketoFieldLoader = ({ request }: LoaderFunctionArgs) => {
	const marketoFields: MarketoParameters = {};
	const searchParams = new URLSearchParams(request.url.split('?')[1]);
	searchParams.forEach((value, key) => {
		marketoFields[key] = value;
	});
	return marketoFields;
};

const otherLoader = ({ params, request }: LoaderFunctionArgs) => {
	return { params, request };
};

export { marketoFieldLoader, otherLoader };
