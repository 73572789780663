import { isNil } from 'lodash';

const getEnvironmentVariable = (variableName: string): string => {
	// throw an error if an env var is missing

	const envVar = process.env[variableName];
	if (isNil(envVar) || envVar === '') {
		throw new Error(`Missing Environment Variable: ${variableName}`);
	}
	return envVar;
};

export default getEnvironmentVariable;
