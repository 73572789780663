export default {
	FIX_BEFORE_SUBMITTING: 'Please fix all errors before submitting.',
	ERROR_POSTING: 'An error occurred while submitting your info',
	ANSWER_REQUIRED_QUESTIONS: 'Please answer the required questions in red',
	FAILED_TO_RETRIEVE_REPORT: `There was an error retrieving your report.`,
	FAILED_TO_POST_ANSWERS: 'An error occurred while submitting your answers',
	FAILED_TO_LOAD_ASSESSMENT: 'An error occurred while loading questions',
	USE_WORK_EMAIL: 'Please enter your work email!',
	X_IS_REQUIRED: (question: string) => `${question} is required.`,
	PAGE_NOT_FOUND: '404 Page Not Found',
	UNEXPECTED_ERROR: 'An unexpected error occurred',
};
