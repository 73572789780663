import React, { type ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	AppBar,
	Box,
	Container,
	Divider,
	Grid,
	Link,
	Toolbar,
	Typography,
} from '@ayx/onyx-ui';
import { paths } from 'enums';
import alteryxLogo from 'assets/images/alteryx-logo.svg';
import LanguageSelector from 'components/inputs/LanguageSelector';
import Footer from './Footer';

const pagesWithFooter = [paths.root];
const pagesWithoutLangSelector = [paths.assessment, paths.results]; // easier than matching report since it has url params

function GlobalWrapper({ children }: { children: ReactNode }) {
	const location = useLocation();
	const [hasFooter, setHasFooter] = useState<boolean>(
		pagesWithFooter.includes(location.pathname)
	);

	useEffect(() => {
		setHasFooter(pagesWithFooter.includes(location.pathname));
	}, [location]);

	return (
		<Box className="min-h-screen flex flex-col justify-between">
			<div>
				<AppBar
					color="default"
					position="static"
					elevation={3}
					role="navigation"
					data-cy="topnav"
				>
					<Toolbar variant="dense" className="p-0 m-0">
						<Grid
							alignItems="center"
							container
							justifyContent="space-between"
							className="px-5 py-2 min-h-[56px] gap-2"
						>
							<div className="flex flex-col sm:flex-row gap-x-5 items-start sm:items-center">
								<Link
									href="https://www.alteryx.com"
									target="_blank"
									className="h-6"
								>
									<img
										src={alteryxLogo}
										className="h-full"
										alt="Alteryx"
									/>
								</Link>
								<Divider
									orientation="vertical"
									role="presentation"
									flexItem
									className="bg-divider invisible sm:visible"
								/>
								<Typography
									variant="body1"
									className="font-montserrat font-normal text-xl"
								>
									Analytics Maturity Assessment
								</Typography>
							</div>
							{!pagesWithoutLangSelector.includes(
								location.pathname
							) && <LanguageSelector />}
						</Grid>
					</Toolbar>
				</AppBar>
				<Container
					maxWidth="xl"
					className="p-4 md:py-14 h-full"
					role="main"
				>
					{children}
				</Container>
			</div>
			<div className="footer--wrapper" role="contentinfo">
				{hasFooter && <Footer />}
			</div>
		</Box>
	);
}

export default GlobalWrapper;
