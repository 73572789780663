import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, FormControl, Autocomplete, TextField } from '@ayx/onyx-ui';
import { Typography, languages } from '@automation-analytics/component-library';
import { noop } from 'lodash';

import type { LanguageShort, Language } from 'types';

// provide enough space for each list box item
const useStyles = makeStyles({
	paper: {
		'& .MuiAutocomplete-option': {
			height: 'auto',
			padding: 4,
			paddingLeft: 8,
			margin: 0,
		},
	},
});

function LanguageSelectorLabel() {
	const { t } = useTranslation();

	return (
		<Trans id="select-language-prompt" t={t}>
			Select your language
		</Trans>
	);
}

interface LanguageSelectorProps {
	onChange?(value: LanguageShort): void;
	readonly className?: string;
}

function LanguageSelectorInput({
	onChange = noop,
	className = '',
}: LanguageSelectorProps) {
	const { i18n } = useTranslation();
	const classes = useStyles();

	const handleChange = (value: Language) => {
		i18n.changeLanguage(value.value);
		if (onChange !== undefined) {
			onChange(value.value);
		}
	};

	return (
		<FormControl className={className}>
			<Autocomplete
				data-cy="language-selector"
				id="language-selector"
				aria-label="Language Selector"
				classes={{ paper: classes.paper }}
				fullWidth
				options={languages}
				getOptionLabel={(option: Language) => option.text}
				renderInput={({ inputProps, ...rest }) => (
					<TextField
						{...rest}
						fullWidth
						value={i18n.language}
						inputProps={{
							...inputProps,
							readOnly: true,
							sx: { cursor: 'pointer' },
						}}
					/>
				)}
				onChange={(
					event: React.SyntheticEvent<Element, Event>,
					value: Language
				) => handleChange(value)}
				isOptionEqualToValue={(option: Language, value: Language) =>
					// enable comparison between Language objects
					option.value === value.value
				}
				disableClearable
				value={languages.find((l) => l.value === i18n.language)}
				multiple={false}
			/>
		</FormControl>
	);
}

function LanguageSelector() {
	return (
		<div>
			<Typography
				variant="subtitle1"
				className="text-base font-light shrink-0"
			>
				<LanguageSelectorLabel />
			</Typography>
			<LanguageSelectorInput className="w-full" />
		</div>
	);
}

export default LanguageSelector;
