import React from 'react';
import { Navigate } from 'react-router-dom';

import paths from 'enums/paths';

interface ErrorBoundaryProps {
	redirectTo?: string;
}

function ErrorBoundary({ redirectTo = paths.error }: ErrorBoundaryProps) {
	// see https://reactrouter.com/en/main/route/error-element

	return <Navigate to={redirectTo as string} replace />;
}

export default ErrorBoundary;
