import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@ayx/onyx-ui';
import { paths } from 'enums';

export default function Footer() {
	const { i18n } = useTranslation();

	const privacyBaseUrl = useMemo(() => {
		switch (i18n.language) {
			case 'en':
				return paths.external.alteryx;
			default:
				return `${paths.external.alteryx}/${i18n.language}`;
		}
	}, [i18n.language]);

	return (
		<div className="bg-white py-5 text-center flex gap-3 md:gap-5 justify-center">
			<Link href={`${privacyBaseUrl}/privacy-policy`}>
				<Trans>Privacy Policy</Trans>
			</Link>
			<Link href={`${privacyBaseUrl}/cookie-notice`}>
				<Trans>Cookie Notice</Trans>
			</Link>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<Link className="ot-sdk-show-settings cursor-pointer">
				<Trans>Cookie Settings</Trans>
			</Link>
		</div>
	);
}
