import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import {
	NotFoundTrigger,
	PageError,
	PageNotFound,
	ScrollToTop,
} from '@automation-analytics/component-library';

import GlobalWrapper from 'components/layout/GlobalWrapper';
import Loading from 'pages/utilities/Loading';
import { marketoFieldLoader } from 'utils/loaders';
import { paths } from 'enums';
import ErrorBoundary from 'navigation/ErrorBoundary';
import Telemetry from 'components/layout/Telemetry';

const LandingPage = lazy(() => import('pages/Landing'));
const ResultsPage = lazy(() => import('pages/Results'));
const ReportPage = lazy(() => import('pages/Report'));
const AssessmentPage = lazy(() => import('pages/Assessment'));
const Versions = lazy(() => import('pages/utilities/Versions'));

const router = createBrowserRouter(
	[
		{
			path: paths.root,
			element: (
				<Telemetry>
					<GlobalWrapper>
						<ScrollToTop />
						<Suspense fallback={<Loading />}>
							<Outlet />
						</Suspense>
					</GlobalWrapper>
				</Telemetry>
			),
			errorElement: <ErrorBoundary redirectTo={paths.error} />,
			children: [
				// public
				{
					index: true,
					element: <LandingPage />,
					loader: ({ params, request }) =>
						marketoFieldLoader({ params, request }),
				},
				{
					path: paths.assessment,
					element: <AssessmentPage />,
				},
				{
					path: paths.results,
					element: <ResultsPage />,
				},
				{
					path: paths.reportRouter,
					element: <ReportPage />,
				},
				{
					path: paths.version,
					element: <Versions />,
				},

				// errors & fallback
				{
					path: paths.wildcard,
					element: <NotFoundTrigger />,
				},
				{
					path: paths.notFound,
					element: <PageNotFound redirectTo={paths.root} />,
				},
				{
					path: paths.error,
					element: <PageError redirectTo={paths.root} />,
				},
			],
		},
	],
	{ basename: process.env.REACT_APP_BASENAME }
);

export default function Router() {
	return <RouterProvider router={router} />;
}
