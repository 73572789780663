import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import {
	AppWrapper,
	UnsupportedPage,
	checkVitals,
} from '@automation-analytics/component-library';
import { AyxAppWrapper } from '@ayx/onyx-ui';

import '@automation-analytics/component-library/dist/tailwind-output.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/styles/index.css';

import Router from 'navigation/Router';
import { APP_SHORT_NAME } from 'enums';
import 'services/NewRelic';
import './i18n';

const IS_UAT_OR_PROD = process.env.NODE_ENV === 'production';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

export default function App() {
	return <Router />;
}

function WrappedApp() {
	return (
		<React.StrictMode>
			<AppWrapper appShortName={APP_SHORT_NAME}>
				<App />
				<ToastContainer icon={false} closeButton={false} />
			</AppWrapper>
		</React.StrictMode>
	);
}

const clientError = checkVitals().shift();

if (!clientError) {
	if (!IS_UAT_OR_PROD) {
		import('@axe-core/react').then((axe) => {
			axe.default(React, ReactDOM, 1000);
			root.render(<WrappedApp />);
		});
	} else {
		root.render(<WrappedApp />);
	}
} else {
	root.render(
		<AyxAppWrapper>
			<UnsupportedPage reason={clientError} />
		</AyxAppWrapper>
	);
}
