import React from 'react';
import { CircularProgress, Container } from '@ayx/onyx-ui';
import { useTranslation } from 'react-i18next';

export default function Loading() {
	const { t } = useTranslation();

	return (
		<Container className="size-full flex justify-center">
			<CircularProgress
				color="primary"
				size={36}
				aria-label={t('loading')}
				className="block"
			/>
		</Container>
	);
}
