const telemetryBaseData = () => {
	return {
		page_domain: window.location.hostname,
		page_path: window.location.pathname,
		page_location: window.location.href,
		page_title: document.title,
		page_url: window.location.origin + window.location.pathname,
	};
};

export default telemetryBaseData;
