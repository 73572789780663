import React, { type ReactNode } from 'react';
import { TelemetryWrapper } from '@ayx/ui-telemetry';
import getEnvironmentVariable from 'utils/getEnvVariable';

export default function Telemetry({ children }: { children: ReactNode }) {
	return (
		<TelemetryWrapper
			getProductInfo={() => ({
				version: getEnvironmentVariable('REACT_APP_VERSION'),
			})}
			productId={getEnvironmentVariable('REACT_APP_TELEMETRY_KEY')}
			environment={getEnvironmentVariable(
				'REACT_APP_TELEMETRY_ENVIRONMENT'
			)}
		>
			{children}
		</TelemetryWrapper>
	);
}
