export default {
	// generic
	wildcard: '*',
	root: '/',

	// errors
	notFound: '/404',
	error: '/error',

	// pages
	assessment: '/assessment',
	results: '/results',
	report: (id: string, token: string) => `/report/${id}/?token=${token}`,
	reportRouter: '/report/:id',
	version: '/version',

	// external
	external: {
		alteryx: 'https://www.alteryx.com',
	},
};
